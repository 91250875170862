import axios from 'axios';

async function updatePaymentMethod(document, setIndex, setLead, setActivatePayment){
    await axios
  .patch(`${process.env.REACT_APP_API_DEV}/leads/${document?.leadId}`, {
    paymentMethod: {
        res: document?.method,
        payAll: document?.checked,
        activationDate: document?.activationDate,
        completedAt: new Date()
    }
  })
  .then(async(res) => {
    if (res?.data) {
        setLead(res?.data)
        if (res?.data?.paymentMethod?.res?.includes("Payer par virement bancaire")) {
          setIndex(3)
        }else if(res?.data?.paymentMethod?.res?.includes("Payer en espèce")){
          setIndex(4)
        }else{
          setActivatePayment(true)
          setIndex(5)
        }
        
    }

  })    
}
export default updatePaymentMethod;