import axios from 'axios';

async function getSettings(setSettings, setLoading, setFaqsData, dispatch){

    await axios
  .get(`${process.env.REACT_APP_API_DEV}/settings`)
  .then(async(res) => {
    setSettings(res.data[0])
    dispatch({ type: 'SET_SETTINGS', payload: res.data[0]});
    setFaqsData(res.data[0]?.campaign?.faqs?.sort((a, b) => (a.index || 0) - (b.index || 0)))
    if (!res.data[0]?.campaign?.activeCampaign) {
      setLoading(false)

    }
  })    
}
export default getSettings;