/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
//import styling
import './styles.css'
// @mui material components
import Grid from "@mui/material/Grid";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Typography } from '@mui/material'
import { CardMedia } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import dayjs from 'dayjs';
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import "react-phone-input-2/lib/style.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import Tooltip from '@mui/material/Tooltip'
//import useDispatch
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import firebase auth
import 'firebase/compat/auth';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import components
import { Navbar } from "layouts/components";
import WhatsappSupport from "layouts/components/whatsappSupport";
import Faqs from "layouts/components/faqs";
import SkeletonTypography from "layouts/components/skeleton";
//import images
import noResultImg from "../../assets/images/no-results.png"
import { useSelector } from 'react-redux';
import getSettings from "layouts/first-screen/functions/getSettings";
import getCompaign from "layouts/first-screen/functions/getCompaign";
import DialogActions from '@mui/material/DialogActions'
import PaymentMethods from "layouts/components/payment-methods";
import moment from 'moment'
import { Checkbox } from 'antd';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from 'antd';
function Basic({setIndex, lead, setLead, returnScreen, setReturnScreen, discount}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [faqsData, setFaqsData] = useState([])
  const [settings, setSettings] = useState({})
  const [campaign, setCampaign] = useState({})
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Payer en ligne");
  const [loadingButton, setLoadingButton] = useState(false)
  const [startDate, setStartDate] = useState(new Date())


  const chosenPack = (id) =>{
    const pack = campaign?.packs?.filter(row => row?._id?.includes(id))
    if (pack?.length > 0) {
      if (discount?.length > 0) {
        let sum = 0
        for (let index = 0; index < discount?.length ; index++) {
          sum += discount[0]?.amount
        }
        return pack[0]?.price - sum
      }
      return pack[0]?.price
    }
    return campaign?.advance
  }

  const onClickButton = async()=>{
    setLoadingButton(true)
   // await updatePaymentMethod({method: selectedPaymentMethod, leadId: lead?._id, checked: checked, activationDate: checked ? startDate : null}, setIndex,  setLead)
    setLoadingButton(false)
    setReturnScreen("")
  }
  const onClickBack = ()=>{
    setReturnScreen("method-payment")
    setIndex(2)
    
  }
  const sendWhatsappMsg = (event) =>{
    event.preventDefault()
      window.open(`${settings?.campaign?.customerServiceWhatsapp}`);
  }
    
  const sumDiscounts = (array) =>{
    let sum = 0
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      sum += element?.amount
    }
    return sum
  }
  const onChange = (value) => {
    if (value) {
      setStartDate(value.format())
     
      } else {
        setStartDate(null);
      }
  };
  const onOk = (value) => {
   // console.log('onOk: ', value);
  };

  useEffect(() => {
    getSettings(setSettings, setLoading, setFaqsData, dispatch)
  }, [])
  useEffect(() =>{
    if (returnScreen == "method-payment") {
      if (lead?.paymentMethod?.res) {
        setSelectedPaymentMethod(lead?.paymentMethod?.res)
      }
    }
  }, [returnScreen])
  useEffect(() =>{
    if (settings?.campaign?.activeCampaign) {
      getCompaign(settings?.campaign?.activeCampaign, setCampaign, dispatch)
      setLoading(false)
    }
  }, [settings])
  return (
    <PageLayout>
       {loading && 
        <Grid
        container
        spacing={1}
        position="fixed"
        justifyContent='center'
        alignItems='center'
        zIndex="1000"

      >
         <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={0}>
         <SkeletonTypography loading={loading}/>
         </Grid>
        
      </Grid>
      }
      {!loading && 
      <MDBox
      px={1}
      width="100%"
      bgColor="#fafafa"
    
      height="100vh"
      style={{
        padding: 30,
        overflowY: 'auto', 
        position: 'relative',  
      }}

      alignItems="center"
      mx='auto'
    >
    <Grid
      container
      spacing={1}
      justifyContent='center'
      alignItems='center'
>
    <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={-14}>
      <Navbar />
      <MDBox textAlign='left'  marginTop={-4.8}>
      <ArrowCircleLeftIcon fontSize="large" color="dark" onClick={onClickBack}/>
      </MDBox>
      <MDBox textAlign="center" marginTop={0.5} >
          <Typography color={"#000000"} variant="h3" fontWeight="bold" textTransform="none" >
          Paie par virement
          </Typography>
        </MDBox>
        <MDBox marginTop={2}>
                    {discount?.length > 0 && lead?.paymentMethod?.payAll &&
                    <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                      <span  style={{fontWeight: "bold", color:"#ff003b"}}>Tu disposes d'une remise de {sumDiscounts(discount)} dhs sur ton prochain achat à utiliser avant le { moment(new Date(discount[0]?.expiryDate)).format('YYYY-MM-DD')}.</span> 
                      <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                      Effectue un virement de <span  style={{fontWeight: "bold"}}>{lead?.paymentMethod?.payAll ? chosenPack(lead?.selectedOffer?.packId) : campaign?.advance}</span> dhs, avant le { moment(new Date(discount[0]?.expiryDate)).format('YYYY-MM-DD')}, au compte suivant:
                </p>
                  </p>
                 
                    }
                    {(!discount?.length || !lead?.paymentMethod?.payAll) &&
                     <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                     Effectue un virement de <span  style={{fontWeight: "bold"}}>{lead?.paymentMethod?.payAll ? chosenPack(lead?.selectedOffer?.packId) : campaign?.advance}</span> dhs au compte suivant:
                   </p>
                    }
                  </MDBox>

                  <MDBox marginTop={3}>
                    <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                        <span  style={{fontWeight: "bold"}}>Titulaire:</span> {settings?.bank?.main?.raisonSocial}
                     </p>
                     <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                        <span  style={{fontWeight: "bold"}}>RIB:</span> {settings?.bank?.main?.rib}
                     </p>
                     <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                        <span  style={{fontWeight: "bold"}}>Banque:</span> {settings?.bank?.main?.name}
                     </p>
                  </MDBox>
                  <MDBox marginTop={3}>
                    <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                       Envoie nous un screenshot sur WhatsApp au <span onClick={(event)=>{sendWhatsappMsg(event)}} style={{fontWeight: "bold", color:"#2699FB", textDecoration:"underline", cursor:"pointer"}}>+{settings?.campaign?.customerServiceWhatsapp?.replace('https://wa.me/', '')}</span> une fois que le virement est fait.
                     </p>
                     
                  </MDBox>
                  <DialogActions sx={{padding: 0, marginTop:4}}>
                    {/**
                     *     <MDButton
                        sx={{paddingTop:"12px"}}
                        variant="outlined"
                        fullWidth
                      //  onClick={onClickButton}
                        color="dark"
                      >
                   Parraine et gangne des remises
                   
                 </MDButton>
                     * 
                     */}
                 
    </DialogActions>
                <MDBox marginTop={2}>
                {settings && 
                  <Faqs faqsData={faqsData} title="Questions fréquemment posées"/>
                  }


                  </MDBox>


   

</Grid>
</Grid>
  
   </MDBox>
      }
         
  </PageLayout>
  );
}

export default Basic;