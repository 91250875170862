import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  '&:before': {
    display: 'none',
  },
  backgroundColor: "#fafafa"
}));

const AccordionSummary = styled(({ expanded, ...props }) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem', color: expanded ? '#26D98C' : 'inherit' }} />}
    {...props}
  />
))(({ theme, expanded }) => ({
  backgroundColor: theme.palette.mode === 'transparent',
}));

const GreenTextTypography = styled(Typography)(({ theme, expanded }) => ({
  color: expanded ? '#26D98C' : 'inherit',
  fontSize: '18px',
  marginLeft:"-20px"
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: 'none',
  marginLeft: "-15px", 
}));

const AnswerTypography = styled(Typography)(({ theme }) => ({
  fontSize: '16px', 

}));

export default function Faqs({ faqsData, title }) {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const formatAnswer = (answer) => {
    return { __html: answer.replace(/\n/g, '<br>') };
  };

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: title == "Questions fréquemment posées" ? "20px" :  "5px"}}>
        <h3>{title}</h3>
      </div>
      <div style={{ marginTop: 5 }}>
        {faqsData && faqsData.map((f) => (
          <Accordion expanded={expanded === `panel${f?.index}`} onChange={handleChange(`panel${f?.index}`)} key={`panel${f?.index}`}>
            <AccordionSummary expanded={expanded === `panel${f?.index}`} aria-controls="panel1d-content" id="panel1d-header">
              <GreenTextTypography expanded={expanded === `panel${f?.index}`} marginLeft={2}>{f?.question}</GreenTextTypography>
            </AccordionSummary>
            <AccordionDetails>
              <AnswerTypography dangerouslySetInnerHTML={formatAnswer(f?.answer)} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
