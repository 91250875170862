import axios from 'axios';

async function getCompaign(id, setCampaign, dispatch){
    await axios
  .get(`${process.env.REACT_APP_API_DEV}/campaigns/${id}`)
  .then(async(res) => {
    setCampaign(res.data)
    dispatch({ type: 'SET_CAMPAIGN', payload: res.data});

  })    
}
export default getCompaign;