import React, { useState, useEffect } from 'react';
import './styles-payment.css'; 

const PaymentMethods = ({ selectedPaymentMethod, setSelectedPaymentMethod, lead }) => {

  const methods = [{"id":"enligne", "value":"Payer en ligne"}, {"id":"virement", "value":"Payer par virement bancaire"}, {"id":"espece", "value":"Payer en espèce"}]
  const colors = {"green": "#26D98C", "light":"#EFF4F2", "purple":"#5D1EF7", "white":"white", "darkGray":"#c4d1e1"}
  const handleButtonClick = (name) =>{
    setSelectedPaymentMethod(name)
     }


    useEffect(() =>{
      for (let index = 0; index < methods?.length; index++) {
        const element = methods[index];
        if (selectedPaymentMethod == element?.value){
          document.getElementById(element?.id).style.borderColor = colors.green
          document.getElementById(element?.id).style.background  = colors.green
        }else{
          document.getElementById(element?.id).style.borderColor = colors.white
          document.getElementById(element?.id).style.background  = colors.white

        }
      }
        
    }, [selectedPaymentMethod])
    return (
      <>
      
         <div className="scrollable-container-offer" style={{marginTop:"20px"}}>
                <div class="grid" style={{marginTop:"15px"}}>
                {methods.map((m, index) => {
            return (
                <label class="card" id={index} style={{
                  borderColor: selectedPaymentMethod === m?.value ? colors.green : colors.darkGray,
                }} >
                <input name="plan"  class="radiom" id={m?.id} type="radio" checked={selectedPaymentMethod == m?.value}
                onClick={(e)=>{handleButtonClick(m?.value)}} 
                />
                
                <span class="plan-details" style={{
                  borderColor: selectedPaymentMethod === m?.value ? colors.green : colors.darkGray,
                }} id={'plan'+index} >
                  <span class="plan-typee">{m?.value}</span>
                 
                </span>
              </label>
            )
        })}
            </div>
                </div>
      </>
    );
  };

export default PaymentMethods;