import axios from 'axios';
import updateOffer from 'layouts/third-screen/functions/updateOffer';
import getDiscount from './getDiscount';
async function create(document, campaign, setIndex, index, setLead, setDiscount){
    await axios
  .post(`${process.env.REACT_APP_API_DEV}/leads`, {
    status: "pending",
    campaignId: document.campaignId,
    mainInfo: {
    name: document.name,
    email: document.email,
    phoneNumber: "+" + document.phoneNumber,
    completedAt: new Date(),
    }
  })
  .then(async(res) => {
    if (res?.data) {
        setLead(res?.data)
        if (campaign?.packs?.length == 1) {
          await updateOffer({packId: campaign?.packs[0]?._id, leadId: res?.data?._id}, setIndex)
        }
        getDiscount(res?.data?.userId, setDiscount)
        setIndex(index)
    }

  })    
}
export default create;