/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import moment from 'moment'
import { useEffect, useState } from "react";
//import styling
import './styles.css'
// @mui material components
import Grid from "@mui/material/Grid";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Typography } from '@mui/material'
import { CardMedia } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import dayjs from 'dayjs';
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import "react-phone-input-2/lib/style.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import Tooltip from '@mui/material/Tooltip'
//import useDispatch
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import firebase auth
import 'firebase/compat/auth';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import components
import { Navbar } from "layouts/components";
import WhatsappSupport from "layouts/components/whatsappSupport";
import Faqs from "layouts/components/faqs";
import SkeletonTypography from "layouts/components/skeleton";
//import images
import noResultImg from "../../assets/images/no-results.png"
import { useSelector } from 'react-redux';
import getSettings from "layouts/first-screen/functions/getSettings";
import getCompaign from "layouts/first-screen/functions/getCompaign";
import DialogActions from '@mui/material/DialogActions'
import PaymentMethods from "layouts/components/payment-methods";
import { useParams } from 'react-router-dom'
import { Checkbox } from 'antd';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import Image from "assets/images/success.png";
function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [faqsData, setFaqsData] = useState([])
  const [settings, setSettings] = useState({})
  const [lead, setLead] = useState({})
  const [completed, setCompleted] = useState("none")
  const id = useParams().id
  const [arrayPaymentInfo, setArrayPaymentInfo] = useState([])


  const startingToday = (date) => {
    const today = new Date();
    const inputDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);
  
    if (today.getTime() === inputDate.getTime()) {
      return "today";
    } else if (inputDate.getTime() > today.getTime()) {
      return "coming";
    }
    return "old";
  };
  useEffect(() => {
    getSettings(setSettings, setLoading, setFaqsData, dispatch)
  }, [])
  useEffect(() =>{
    if (settings?.bank) {

      arrayPaymentInfo.push({
        index: 500,
        question: "Régler le complément par virement",
        answer: `Paie la totalité du complément en une fois par virement au compte bancaire suivant: \n\n<span class="highlight">Titulaire</span>: ${settings?.bank?.main?.name} \n<span class="highlight">RIB</span>: ${settings?.bank?.main?.rib}\n\nEnvoie nous un screenshot sur WhatsApp au +<span class="highlight">${settings?.campaign?.customerServiceWhatsapp?.replace('https://wa.me/', '')}</span> une fois que le virement est fait.`
    },
    {
      index: 501,
      question: "Régler le complément par chèque(s) ou espèce",
      answer: `Tu peux aller jusqu'à 4 chèques sur les 4 premiers mois. La première échéance sera la date d'activation. Dépose les chèques ou l'espèce dans nos locaux ou au point de relais.
      
      <span class="highlight">Nos bureaux: </span>
      Bureau 205, étage 2, aile gauche, Technopark (à côté de Marjane Californie), Casablanca.
      <span class="highlight-green">Ouverts du lundi au vendredi de 9h30 à 18h30.</span>
      
      <span class="highlight">Point de relais:</span>
      La Dragée d’Or, 221, Boulevard d’Anfa, Casablanca (en face de l’école espagnole Juan Ramon).
      <span class="highlight-green">Ouverts du lundi au samedi de 9h30 à 19h30 
      Dimanche de 11h à 18h</span>`
    }
    );
    
    }
  }, [settings])
  useEffect(async() =>{
   if (id) {
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/leads/by-userId/${id}`)
    .then(async(res) => {
      if (res?.data) {
          setLead(res?.data)
          if (res?.data?.status == "completed") {
            setCompleted("completed")
          }else if (res?.data?.status == "pending" && res?.data?.payment?.completedAt) {
            setCompleted("pending")
          }else{
            setCompleted("none")
          }
      }
    }) 
   }
  }, [id])
  return (
    <PageLayout>
      {completed == "completed" && 
            <MDBox
            px={1}
            width="100%"
            bgColor="#fafafa"
          
            height="100vh"
            style={{
              padding: 30,
              overflowY: 'auto', 
              position: 'relative',  
            }}
      
            alignItems="center"
            mx='auto'
          >
             
            
           
            <Grid
              container
              spacing={1}
              justifyContent='center'
              alignItems='center'
     
            >
              <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <MDBox textAlign="center" marginTop={-8} >
              <MDBox textAlign="center" >
                  <div className="iconBack">
                    <img className="successIcon" src={Image} alt="image description" />
                  </div>
                </MDBox>
              <Typography color={"dark"} variant='h4' justifyContent='center' fontWeight='bold' marginTop={-10}>
              Paiement effectué avec succèss
              </Typography>
              </MDBox>
              <Box>
                  <MDBox
                    //coloredShadow='dark'
                    //pt={4}
                    //pb={3}
                    //px={3}
                    marginTop={1}
                    >
                    <MDBox textAlign="center"
                     marginTop={2}
                    >
                      {startingToday(lead?.paymentMethod?.activationDate).includes("today") && 
                      <p style={{color:"black", fontSize:"16px", textAlign:"left"}}> <span style={{color:"#26D98C", fontWeight:"bold"}}>Ton pass est maintenant actif.</span> Rends toi sur l'application pour réserver ton premier cours.</p>
                      }
                      {startingToday(lead?.paymentMethod?.activationDate).includes("coming") && 
                      <p style={{color:"black", fontSize:"16px", textAlign:"left"}}>Ton pass s'activera automatiquement le matin du <span style={{color:"#26D98C", fontWeight:"bold"}}>{moment(lead?.paymentMethod?.activationDate).format('YYYY-MM-DD')}</span>. </p>
                      }
                      {startingToday(lead?.paymentMethod?.activationDate).includes("old") && 
                      <p style={{color:"black", fontSize:"16px", textAlign:"left"}}>Ton pass s'est activé le matin du <span style={{color:"#26D98C", fontWeight:"bold"}}>{moment(lead?.paymentMethod?.activationDate).format('YYYY-MM-DD')}</span>. </p>
                      }
                    </MDBox>

                  </MDBox>
                  <MDBox marginTop={2}>
                {settings && 
                  <Faqs faqsData={faqsData} title="Questions fréquemment posées"/>
                  }
                </MDBox>
                </Box>
              </Grid>
            </Grid>

          </MDBox>
      }
   
   {completed == "pending" && 
            <MDBox
            px={1}
            width="100%"
            bgColor="#fafafa"
          
            height="100vh"
            style={{
              padding: 30,
              overflowY: 'auto', 
              position: 'relative',  
            }}
      
            alignItems="center"
            mx='auto'
          >
             
            
           
            <Grid
              container
              spacing={1}
              justifyContent='center'
              alignItems='center'
     
            >
              <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <MDBox textAlign="center" marginTop={-8} >
              <MDBox textAlign="center" >
                  <div className="iconBack">
                    <img className="successIcon" src={Image} alt="image description" />
                  </div>
                </MDBox>
              <Typography color={"dark"} variant='h4' justifyContent='center' fontWeight='bold' marginTop={-10}>
              Paiement effectué avec succèss
              </Typography>
              </MDBox>
              <Box>
                  <MDBox
                    //coloredShadow='dark'
                    //pt={4}
                    //pb={3}
                    //px={3}
                    marginTop={1}
                    >
                    <MDBox textAlign="center"
                     marginTop={2}
                    >
                       <p style={{color:"black", fontSize:"16px", textAlign:"left"}}> <span style={{color:"#26D98C", fontWeight:"bold"}}>Ta place pour la promo est bien bloquée.</span> Tu as <span style={{fontWeight:"bold"}}>un mois</span> pour compléter le reliquat et ainsi activer ton pass. Tu peux toujours demander un remboursement de ton avance si tu changes d'avis pendant les 30 prochains jours.</p>
                    </MDBox>
                    {
                      settings &&
                      <Faqs faqsData={arrayPaymentInfo} title=""/> 
                      
                    }
                  </MDBox>
                  <MDBox marginTop={2}>

                </MDBox>
                </Box>
              </Grid>
            </Grid>

          </MDBox>
      }
     
      </PageLayout>
  );
}

export default Basic;