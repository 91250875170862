/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
//import styling
import './styles.css'
// @mui material components
import Grid from "@mui/material/Grid";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Typography } from '@mui/material'
import { CardMedia } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import Tooltip from '@mui/material/Tooltip'
//import useDispatch
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import firebase auth
import 'firebase/compat/auth';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import components
import { Navbar } from "layouts/components";
import WhatsappSupport from "layouts/components/whatsappSupport";
import Faqs from "layouts/components/faqs";
import SkeletonTypography from "layouts/components/skeleton";
//import images
import noResultImg from "../../assets/images/no-results.png"
import { useSelector } from 'react-redux';
import getSettings from "layouts/first-screen/functions/getSettings";
import getCompaign from "layouts/first-screen/functions/getCompaign";
import DialogActions from '@mui/material/DialogActions'
import Offers from "layouts/components/offers";
import updateOffer from "./functions/updateOffer";

import CircularProgress from '@mui/material/CircularProgress';
function Basic({setIndex, lead, setLead, returnScreen, setReturnScreen}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [faqsData, setFaqsData] = useState([])
  const [settings, setSettings] = useState({})
  const [campaign, setCampaign] = useState({})
  const [loading, setLoading] = useState(true)
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false)



  const onClickButton = async()=>{
    console.log(lead)
    
    setLoadingButton(true)
    await updateOffer({packId: selectedOffer, leadId: lead?._id}, setIndex, setLead)
    setLoadingButton(false)
    setReturnScreen("method-payment")
  }
  const onClickBack = ()=>{
    setReturnScreen("main")
    setIndex(0)
  }

  useEffect(() => {
    getSettings(setSettings, setLoading, setFaqsData, dispatch)
  }, [])

  useEffect(() =>{
    if (settings?.campaign?.activeCampaign) {
      getCompaign(settings?.campaign?.activeCampaign, setCampaign, dispatch)
      setLoading(false)
    }
  }, [settings])


  return (
    <PageLayout>
       {loading && 
        <Grid
        container
        spacing={1}
        position="fixed"
        justifyContent='center'
        alignItems='center'
        zIndex="1000"

      >
         <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={0}>
         <SkeletonTypography loading={loading}/>
         </Grid>
        
      </Grid>
      }
      {!loading && 
      <MDBox
      px={1}
      width="100%"
      bgColor="#fafafa"
    
      height="100vh"
      style={{
        padding: 30,
        overflowY: 'auto', 
        position: 'relative',  
      }}

      alignItems="center"
      mx='auto'
    >
    <Grid
      container
      spacing={1}
      justifyContent='center'
      alignItems='center'
>
    <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={-14}>
      <Navbar />
      <MDBox textAlign='left'  marginTop={-4.8}>
      <ArrowCircleLeftIcon fontSize="large" color="dark" onClick={onClickBack}/>
      </MDBox>
      <MDBox textAlign="center" marginTop={1} >
          <Typography color={"#000000"} variant="h3" fontWeight="bold" textTransform="none" >
          Choisis la formule qui t'intéresse le plus
          </Typography>
        </MDBox>
                  <MDBox marginTop={2}>
                    <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                       Peu importe la formule choisie, c'est la même avance de {campaign?.advance} dhs pour bloquer ta place.
                     </p>
                     <p style={{fontSize: "16px", color:"black", justifyContent:"center", textAlign:"left"}}>
                       Tu peux à tout moment changer d'avis et choisir l'autre carnet par la suite!
                     </p>
                  </MDBox>
                  <MDBox marginTop={2}>
                {campaign?.packs && 
                  <Offers packs={campaign?.packs} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer} returnScreen={returnScreen} lead={lead}/>
                  }
                  </MDBox>
                  <DialogActions sx={{padding: 0}}>
               
                    
                     <MDButton
                     fullWidth
                     onClick={onClickButton}
                     color= {"green"}
                     disabled={loadingButton}
                     >
                     Continuer
                     {loadingButton && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#EBEFF4",
                    marginLeft: '5px',
                  }}
                />
              )}
                     </MDButton>
    </DialogActions>
                <MDBox marginTop={5}>
                {settings && 
                  <Faqs faqsData={faqsData} title="Questions fréquemment posées"/>
                  }
                  </MDBox>


   

</Grid>
</Grid>
  
   </MDBox>
      }
         
  </PageLayout>
  );
}

export default Basic;
