import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import FirstScreen from "./first-screen"
import SecondScreen from "./second-screen"
import { UserProvider } from '../contexte/userProvider';

import "./style.css"
function Swipeableviews() {
    const [index, setIndex] = useState(0)

    useEffect(() => {   
    //  console.log("submitted : ",submitted)
    }, []);
    return (
      <UserProvider>
      <div>
        {/**
         *  {submitted &&
        <Confetti 
        numberOfPieces={800}
        recycle={false}
        gravity={0.1}
        colors={['#f44336','#e91e63','#9c27b0','#673ab7','#3f51b5','#2196f3','#03a9f4','#00bcd4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800','#FF5722','#795548']}
      
     />
      }
         */}
     
        <SwipeableViews index={index} onChangeIndex={setIndex} disabled >
            <div>
            <FirstScreen setIndex={setIndex}  />
            </div>  
            <div>
            <SecondScreen setIndex={setIndex}  />
            </div>  
        </SwipeableViews>
        
      </div>
      </UserProvider>
    );
}

export default Swipeableviews;