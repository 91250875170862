import React, { useState, useEffect } from 'react';
import './styles.css'; 

const PromoBanner = ({ text }) => {
    const [showSlide, setShowSlide] = useState(true); // Set initial state to true
    const textArray = text?.split('!');
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setShowSlide((prevShowSlide) => !prevShowSlide);
      }, 2000);
      return () => clearInterval(intervalId);
    }, []);
  
    return (
      <>
        {text && (
          <div
            className={`promo-banner`}
          >
            <p className={`promo-text1`}>
              {'BLOQUE TA PLACE'}
            </p>
            <p className={`promo-text2`}>
              {showSlide ? textArray[0] : textArray[1] }
            </p>
          </div>
        )}
      </>
    );
  };

export default PromoBanner;

