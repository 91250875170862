import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import SecondScreen from "layouts/second-screen"
import ThirdScreen from "layouts/third-screen"
import FourthScreen from 'layouts/fourth-screen'
import FifthScreen from 'layouts/fifth-screen'
import SixthScreen from 'layouts/sixth-screen'
import SeventhScreen from 'layouts/seventh-screen'
import { UserProvider } from '../../contexte/userProvider';
import WhatsappSupport from 'layouts/components/whatsappSupport';

import axios from 'axios';
import "./styles.css"
function Swipeableviews() {
    const [index, setIndex] = useState(0)
    const [returnScreen, setReturnScreen] = useState("")
    const [lead, setLead] = useState({})
    const [settings, setSettings] = useState({})
    const [discount, setDiscount] = useState([])
    const [activatePayment, setActivatePayment] = useState(false)
    useEffect(async() => {   
      await axios
      .get(`${process.env.REACT_APP_API_DEV}/settings`)
      .then(async(res) => {
        setSettings(res.data[0])
      }) 
    }, []);
    return (
      <UserProvider>
      <div>
        {/**
         *  {submitted &&
        <Confetti 
        numberOfPieces={800}
        recycle={false}
        gravity={0.1}
        colors={['#f44336','#e91e63','#9c27b0','#673ab7','#3f51b5','#2196f3','#03a9f4','#00bcd4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800','#FF5722','#795548']}
      
     />
      }
         */}
      <WhatsappSupport linkWtsp={settings?.campaign?.customerServiceWhatsapp}/>
        <SwipeableViews index={index} onChangeIndex={setIndex} disabled >
            <div>
            <SecondScreen setIndex={setIndex} lead={lead} setLead={setLead} returnScreen={returnScreen} setReturnScreen={setReturnScreen} setDiscount={setDiscount}/>
            </div>  
            <div>
            <ThirdScreen setIndex={setIndex} lead={lead} setLead={setLead} returnScreen={returnScreen} setReturnScreen={setReturnScreen} />
            </div>  
            <div>
            <FourthScreen setIndex={setIndex} lead={lead} setLead={setLead} returnScreen={returnScreen} setReturnScreen={setReturnScreen} setActivatePayment={setActivatePayment} />
            </div>
            <div>
            <FifthScreen setIndex={setIndex} lead={lead} setLead={setLead} returnScreen={returnScreen} setReturnScreen={setReturnScreen} discount={discount} />
            </div>
            <div>
            <SixthScreen setIndex={setIndex} lead={lead} setLead={setLead} returnScreen={returnScreen} setReturnScreen={setReturnScreen} discount={discount}/>
            </div>
            <div>
            <SeventhScreen setIndex={setIndex} lead={lead} setLead={setLead} returnScreen={returnScreen} setReturnScreen={setReturnScreen} activatePayment={activatePayment}/>
            </div>
        </SwipeableViews>
        
      </div>
      </UserProvider>
    );
}

export default Swipeableviews;