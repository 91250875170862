import { SET_USER, SET_SETTINGS, SET_CAMPAIGN } from './actions';

const initialState = {
  user: null,
  settings: null,
  campaign: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload
      };
      case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
      case SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload
      };

      return {
        ...state,
        settingsImg: action.payload
      };
    default:
      return state;
  }
}