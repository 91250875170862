import React, { useRef, useState } from 'react';
// Import carpusel React components
import { Carousel } from 'antd';

import './styles.css';

// import react components

import MDBox from "components/MDBox";

export default function SwiperComponent({packs, daysToMonths}) {
  return (
    <Carousel autoplay dotPosition="top" autoplaySpeed={3000} effect="scrollx"  >
                   {
                  packs && packs.map((p)=>(
                    <MDBox textAlign="center" marginTop={0}>
                    <div className="iconPromo">

                      <img className="iconImgPromo" src={p?.image} alt="image description" />
                    </div>
                    <p className="valid">Valable pour {daysToMonths(p?.duration)} mois</p>
                  </MDBox>
                  ))
                    }
                </Carousel>
  );
}