import React, { useState, useEffect } from 'react';
import './styles.css'; 

const Offers = ({ packs, selectedOffer, setSelectedOffer, returnScreen, lead }) => {
 
  const colors = {"green": "#26D98C", "light":"#EFF4F2", "purple":"#5D1EF7", "white":"white", "darkGray":"#c4d1e1"}
  const handleButtonClick = (event, e1, e2, e3) =>{
      setSelectedOffer(e2);
      document.getElementById(e2).style.borderColor = colors.green
      document.getElementById(e2).style.background  = colors.green
      document.getElementById(e1).style.borderColor = colors.green
      document.getElementById(e3).style.borderColor = colors.green
      for (let index = 0; index < packs?.length; index++) {
        const element = packs[index];
        if (!e2?.includes(element?._id)) {
          document.getElementById(element?._id).style.borderColor = colors.white
          document.getElementById(element?._id).style.background  = colors.white
          document.getElementById(index).style.borderColor = colors.darkGray
          document.getElementById('plan'+index).style.borderColor = colors.darkGray
        }
      }
     }
    function daysToMonths(days) {
        const daysInMonth = 30; 
        const months = Math.floor(days / daysInMonth);
        return months;
      }

    useEffect(() =>{
      if (packs?.length > 0) {
        
        if (returnScreen == "offers") {
          for (let index = 0; index < packs?.length; index++) {
            const element = packs[index];
            if (element?._id == lead?.selectedOffer?.packId) {
              setSelectedOffer(lead?.selectedOffer?.packId)
              document.getElementById(element?._id).style.borderColor = colors.green
              document.getElementById(element?._id).style.background  = colors.green
              document.getElementById(index).style.borderColor = colors.green
              document.getElementById('plan'+index).style.borderColor = colors.green
            }else{
              document.getElementById(element?._id).style.borderColor = colors.white
              document.getElementById(element?._id).style.background  = colors.white
              document.getElementById(index).style.borderColor = colors.darkGray
              document.getElementById('plan'+index).style.borderColor = colors.darkGray
            }
              
          }
        }else{
          setSelectedOffer(packs[0]?._id)
          document.getElementById(packs[0]?._id).style.borderColor = colors.green
          document.getElementById(packs[0]?._id).style.background  = colors.green
          document.getElementById(0).style.borderColor = colors.green
          document.getElementById('plan'+0).style.borderColor = colors.green
          for (let index = 1; index < packs?.length; index++) {
            const element = packs[index];
              document.getElementById(element?._id).style.borderColor = colors.white
              document.getElementById(element?._id).style.background  = colors.white
              document.getElementById(index).style.borderColor = colors.darkGray
              document.getElementById('plan'+index).style.borderColor = colors.darkGray
          }
        }
        
      }
    }, [packs, returnScreen])
    return (
      <>
      
         <div className="scrollable-container-offer" style={{marginTop:"20px"}}>
                <div class="grid" style={{marginTop:"15px"}}>
                {packs.map((p, index) => {
            return (
                <label class="card" id={index} >
                <input name="plan" class="radio" id={p?._id} type="radio" checked={selectedOffer === p?._id}
                onClick={(e)=>{handleButtonClick(e, index, p?._id, 'plan'+index)}} 
                />
                
                <span class="plan-details" id={'plan'+index} >
                  <span class="plan-type">{p?.title}</span>
                  <span class="plan-cost">{p?.price} dhs <span class="plan-text"> (validité de {daysToMonths(p?.duration)} mois)</span></span>
                </span>
              </label>
            )
        })}
            </div>
                </div>
      </>
    );
  };

export default Offers;