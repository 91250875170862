import React from 'react';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MDBox from 'components/MDBox';
import './styles.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    greenWhatsapp: {
      main: '#25d366',
    },
  },
});

const WhatsappSupport = ({linkWtsp}) => {
  const message = "Bonjour, je suis intéressé(e) par la promo "
  const buttonStyle = {
    borderRadius: '25px',
    color: 'white',
  };

const handleClickWtsp = (event) =>{
  event.preventDefault()
    window.open(`${linkWtsp}?text=${message}`);
}

  return (
    <ThemeProvider theme={theme}>

      <div
        className="whatsSupport"
      >
        <Button
          style={buttonStyle}
          color="greenWhatsapp"
          variant="contained"
          onClick={(event)=>{handleClickWtsp(event)}}
          startIcon={<WhatsAppIcon />}
        >
          Parle nous ici
        </Button>
      </div>
    </ThemeProvider>
  );
};

export default WhatsappSupport;