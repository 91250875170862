import axios from 'axios';

async function getDiscount(userId, setDiscount){
    await axios
  .get(`${process.env.REACT_APP_API_DEV}/discounts/by-user?user=${userId}&notExpired=${true}&status=available`)
  .then(async(res) => {
    if (res?.data) {
        setDiscount(res?.data)
    }
  })    
}
export default getDiscount;