import axios from 'axios';

async function updateOffer(document, setIndex, setLead){
    await axios
  .patch(`${process.env.REACT_APP_API_DEV}/leads/${document?.leadId}`, {
    selectedOffer: {
    packId: document?.packId,
    completedAt: new Date()
    }
  })
  .then(async(res) => {
    if (res?.data) {
        setLead(res?.data)
        setIndex(2)
    }

  })    
}
export default updateOffer;