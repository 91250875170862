import axios from 'axios';

async function updateMainInfo(document, navigatePages){
    await axios
  .patch(`${process.env.REACT_APP_API_DEV}/leads/${document?.leadId}`, {
    mainInfo: {
    name: document?.name,
    email: document?.email,
    phoneNumber: "+" + document?.phoneNumber,
    completedAt: new Date(),
    }
  })
  .then(async(res) => {
   navigatePages(res?.data)

  })    
}
export default updateMainInfo;