import axios from 'axios';

async function getGyms(setGyms){
    await axios
  .get(`${process.env.REACT_APP_API_DEV}/gyms/active/gyms`)
  .then(async(res) => {
    setGyms(res.data)

  })    
}
export default getGyms;