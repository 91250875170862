/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
//import styling
import './styles.css'
// @mui material components
import Grid from "@mui/material/Grid";
import { Typography } from '@mui/material'
import { CardMedia } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
//import useDispatch
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import firebase auth
import 'firebase/compat/auth';
import axios from 'axios';
//import components
import { Navbar } from "layouts/components";
import WhatsappSupport from "layouts/components/whatsappSupport";
import Faqs from "layouts/components/faqs";
import SkeletonTypography from "layouts/components/skeleton";
//import images
import noResultImg from "../../assets/images/no-results.png"
import { useSelector } from 'react-redux';
import getSettings from "layouts/first-screen/functions/getSettings";
import getCompaign from "layouts/first-screen/functions/getCompaign";
import getLead from "./functions/getLead";
import create from "./functions/create";
import CircularProgress from '@mui/material/CircularProgress';
import updateOffer from "layouts/third-screen/functions/updateOffer";
import updateMainInfo from "./functions/updateMainInfo";
import getDiscount from "./functions/getDiscount";
function Basic({setIndex, lead, setLead, returnScreen, setReturnScreen, setDiscount}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [faqsData, setFaqsData] = useState([])
  const [settings, setSettings] = useState({})
  const [campaign, setCampaign] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [validPhone, setValidPhone] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const phoneError = "Numéro de téléphone invalide"
  const [disabledButton, setDisabledButton] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const onChangeName = (e) =>{
    const regex = /^[a-zA-Z\s]*$/; 

    if (regex.test(e.target.value)) {
      setName(e.target.value);
    }
  }
  function isValidEmail(e) {
    return /\S+@\S+\.\S+/.test(e)
  }
  const onChangeEmail = (e) =>{
    setEmail(e.target.value.toLowerCase());
   if (isValidEmail(e.target.value.toLowerCase())) {
    setEmail(e.target.value.toLowerCase());
    setValidEmail(true)
    setError("")
   }else{
    setValidEmail(false)
     setError("email")
   }
    
  }
  const onChangePhoneNumber = (e) =>{
    setError(false)
    setPhoneNumber(e)
    
  }

  const navigatePages = async(leadDocument) =>{
    if (leadDocument?.status == "completed" || (leadDocument?.status == "pending" && leadDocument?.payment?.completedAt) ) {
      navigate(`/success-promo/${leadDocument?.userId}`);
    }else if (returnScreen == "main") {
      if (campaign?.packs?.length > 1) {
        if (lead?.selectedOffer?.packId) {
          setReturnScreen("offers")
        }else{
          setReturnScreen("")
        }
        setIndex(1)
      }else{

        if (campaign?.packs?.length == 1) {
          await updateOffer({packId: campaign?.packs[0]?._id, leadId: leadDocument?._id}, setIndex, setLead)
        }
        setReturnScreen("method-payment")
        setIndex(2)
      }
    }else{
      if (campaign?.packs?.length > 1) {
        if (leadDocument?.selectedOffer?.packId) {
          setReturnScreen("offers")
        }else{
          setReturnScreen("")
        }
        setIndex(1)
      }else{
        if (campaign?.packs?.length == 1) {
          console.log(campaign?.packs[0]?._id)
          await updateOffer({packId: campaign?.packs[0]?._id, leadId: leadDocument?._id}, setIndex, setLead)
        }
        setReturnScreen("method-payment")
        setIndex(2)
      }
      
    }
   
  }

  const onClickButton = async()=>{
    setLoadingButton(true)
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/leads/by-email/${email}?campaingId=${settings?.campaign?.activeCampaign}`)
    .then(async(res) => {
      if (res?.data) {
        setLead(res?.data)
        getDiscount(res?.data?.userId, setDiscount)
        updateMainInfo({
          name: name,
          email: email,
          phoneNumber: phoneNumber,
          leadId:res?.data?._id}, navigatePages )
      //  navigatePages(res?.data)
        setLoadingButton(false)
      }else{
        await create( {
          campaignId: campaign?._id,
          name: name,
          email: email,
          phoneNumber: phoneNumber,
        }, campaign, setIndex, campaign?.packs?.length > 1 ? 1 : 2, setLead, setDiscount)
        setLoadingButton(false)
      }
    }) 
  }
  useEffect(() =>{
    if (name.length > 0 && validPhone && validEmail && phoneNumber.length > 6) {
      setDisabledButton(false)
    }else{
      setDisabledButton(true)
    }
  }, [name, validPhone, phoneNumber, validEmail])
  useEffect(() => {
    getSettings(setSettings, setLoading, setFaqsData, dispatch)
  }, [])
  useEffect(() =>{
    if (settings?.campaign?.activeCampaign) {
      getCompaign(settings?.campaign?.activeCampaign, setCampaign, dispatch)
      setLoading(false)
    }
  }, [settings])
  return (
    <PageLayout>
       {loading && 
        <Grid
        container
        spacing={1}
        position="fixed"
        justifyContent='center'
        alignItems='center'
        zIndex="1000"

      >
         <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={0}>
         <SkeletonTypography loading={loading}/>
         </Grid>
        
      </Grid>
      }
      {!loading && 
      <MDBox
      px={1}
      width="100%"
      bgColor="#fafafa"
    
      height="100vh"
      style={{
        padding: 30,
        overflowY: 'auto', 
        position: 'relative',  
      }}

      alignItems="center"
      mx='auto'
    >
    <Grid
      container
      spacing={1}
      justifyContent='center'
      alignItems='center'
>
    <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={-10}>
      <Navbar />

      <MDBox textAlign="center" marginTop={4} >
          <Typography color={"#000000"} variant="h3" fontWeight="bold" textTransform="none" >
          Remplis le formulaire
          </Typography>
        </MDBox>

      
      <MDBox marginTop={2} component='form' role='form'>
                  <MDBox mb={2}>
                    <MDInput
                      error={error == "email" ? true : false}
                    //  helperText={error == "email" ? "Prière de saisir un mail valide." : ""}
                      type='email'
                      label={error == "email" ? "Prière de saisir un mail valide" : 'Saisis ton adresse mail'}
                      onChange={onChangeEmail}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2} >
                 <PhoneInput
                  label="phone"
                    preferredCountries={['ma','fr']}
                    countryCodeEditable={false}
                    placeholder="Entrez votre numéro de tel"
                    country={'ma'}
                    isValid={(value, country) => {
                      if (value.substring(0, 3) == "212" && value.length > 3) {
                        if (value.length != 12) {
                          setValidPhone(false)
                          return phoneError
                        }else{
                          setValidPhone(true)
                          return true
                        }
                       }else if(value.substring(0, 2) == "33" && value.length > 2){
                        if (value.length != 11) { 
                          setValidPhone(false)
                          return phoneError
                        }else{
                          setValidPhone(true)
                          return true
                        }
                       }else if(value.substring(0, 2) == "34" && value.length > 2){
                         if (value.length != 11) {
                          setValidPhone(false)
                          return phoneError
                         }else{
                          setValidPhone(true)
                          return true
                         }
                       }else if(value.substring(0, 2) == "44"&& value.length > 2){
                         if (value.length != 12)  {
                          setValidPhone(false)
                          return phoneError
                         }else{
                          setValidPhone(true)
                          return true
                         }
                       }else if(value.length <= 6 && value.substring(0, 2) != "44" && value.substring(0, 2) != "34" && value.substring(0, 2) != "33" && value.substring(0, 3) != "212") {
                        setValidPhone(false)
                        return false
                       }else{
                        setValidPhone(true)
                        return true
                       }
                    }}
                    onChange={onChangePhoneNumber}
                    containerClass="my-container-class"
                    inputClass="my-input-class"
                    containerStyle={{
                      width:'100%'
                    }}
                    inputStyle={{
                      backgroundColor: "#fafafa",
                      borderColor: validPhone ? "#CED4DA" : "#ff003b",
                      borderRadius:"6px",
                      width:'100%',
                      height: 46,
                    }}
                    buttonStyle={{
                      borderColor: validPhone ? "#CED4DA" : "#ff003b",
                      borderTopLeftRadius:"6px",
                      borderBottomLeftRadius:"6px",
                      backgroundColor:'#EFF4F2',
                      height: 46,
                      width:45
                    }}
                />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      type='text'
                      value={name}
                      label="Saisis ton nom"
                      onChange={onChangeName}
                      fullWidth
                    />
                  </MDBox>
             
                
                  <MDBox marginTop={2}>
                    {disabledButton &&
                     <MDButton
                     fullWidth
                     // onClick={onClickButton}
                     color= {"light"}
                     >
                     Continuer
 
                     </MDButton>
                    }
                 {!disabledButton &&
                     <MDButton
                     fullWidth
                     onClick={onClickButton}
                     color= {"green"}
                     disabled={loadingButton}
                     >
                     Continuer
                     {loadingButton && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#EBEFF4",
                    marginLeft: '5px',
                  }}
                />
              )}
                     </MDButton>
                    }
                  </MDBox> 
                </MDBox>

                <MDBox marginTop={5}>
                {settings && 
                  <Faqs faqsData={faqsData} title="Questions fréquemment posées"/>
                  }
                  </MDBox>
</Grid>
</Grid>
  
   </MDBox>
      }
         
  </PageLayout>
  );
}

export default Basic;
