/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from 'react';
// @mui material components
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// Material Dashboard 2 React example components
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { toast } from 'react-toastify'
import { useEffect, useState } from "react";
import FolderIcon from '@mui/icons-material/Folder';
import DialogActions from '@mui/material/DialogActions'
import iconButton from "assets/theme/components/iconButton";
import './styles.css'
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
toast.configure()
function ViewGymsAccess({ open, setOpen, gyms }) {
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle className='dialogTitle'>
          <p style={{marginLeft:"20px"}}>Liste des partenaires</p>
          
          <iconButton onClick={handleClose} style={{cursor: "pointer"}} >
            <CloseIcon color="dark" />
        </iconButton>
          </DialogTitle>
        <MDBox >
            <div className="scrollable-container-offer" style={{marginTop:"15px"}}>
                    <div class="grid">
                    {gyms.map((g) => (
                        <label class="card" key={g?._id} style={{marginTop:"-1px", paddingLeft:"10px", paddingRight:"10px"}}>
                        <span class="plan-detailss" style={{paddingTop: "3px"}}>
                        <span class="plan-costt" style={{fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{g?.name}</span>
                        <span class="plan-typeee">{g?.maxEntry ? g?.maxEntry + ' accès/mois' : 'illimité'}</span>
                        </span>
                    </label>  
            ))}
            
        
                    </div>
                </div>
        </MDBox>


      </Dialog>
    );
  }
  
  export default ViewGymsAccess;