export const SET_USER = 'SET_USER';
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_CAMPAIGN = 'SET_CAMPAIGN'


export function setUser(user) {
  return {
    type: SET_USER,
    payload: user
  };
}

export function setCampaign(campaign) {
  return {
    type: SET_CAMPAIGN,
    payload: campaign
  };
}

export function setSettings(settings) {
  return {
    type: SET_SETTINGS,
    payload: settings
  };
}

