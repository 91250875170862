/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import moment from 'moment'
import { useEffect, useState, useRef  } from "react";
//import styling
import './styles.css'
// @mui material components
import Grid from "@mui/material/Grid";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Typography } from '@mui/material'
import { CardMedia } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import dayjs from 'dayjs';
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import "react-phone-input-2/lib/style.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import Tooltip from '@mui/material/Tooltip'
//import useDispatch
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import firebase auth
import 'firebase/compat/auth';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import components
import { Navbar } from "layouts/components";
import WhatsappSupport from "layouts/components/whatsappSupport";
import Faqs from "layouts/components/faqs";
import SkeletonTypography from "layouts/components/skeleton";
//import images
import noResultImg from "../../assets/images/no-results.png"
import { useSelector } from 'react-redux';
import getSettings from "layouts/first-screen/functions/getSettings";
import getCompaign from "layouts/first-screen/functions/getCompaign";
import DialogActions from '@mui/material/DialogActions'
import PaymentMethods from "layouts/components/payment-methods";

import { Checkbox } from 'antd';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from 'antd';
function Basic({setIndex, lead, setLead, returnScreen, setReturnScreen, activatePayment}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [faqsData, setFaqsData] = useState([])
  const [settings, setSettings] = useState({})
  const [campaign, setCampaign] = useState({})
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [codePayment, setCodePayment] = useState(`<!DOCTYPE html>
  <html>
    <head>
      <title></title>
    </head>
    <body>
      <h1></h1>
      <p></p>

    </body>
  </html>`)
  const getPaymentForm = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/orders/promo-sub-pack?packId=${lead?.selectedOffer?.packId}&userId=${lead?.userId}`)
    .then((res)=>{
        console.log(String(res.data))
        setCodePayment(String(res.data))
        setSuccess(true)
        setLoading(false)

    })
  }
  const containerRef = useRef(null);
  useEffect(() =>{
    if (activatePayment) {
        getPaymentForm()
      
    }
  }, [activatePayment])

  useEffect(() => {
    if (success) {
        const scriptNodes = containerRef.current.getElementsByTagName('script');
        for (let i = 0; i < scriptNodes.length; i++) {
          const node = scriptNodes[i];
          const script = document.createElement('script');
          script.innerHTML = node.innerHTML;
          node.parentNode.replaceChild(script, node);
        }
    }

  }, [success]);
  return (
    <PageLayout>
        {loading && 
        <Grid
        container
        spacing={1}
        position="fixed"
        justifyContent='center'
        alignItems='center'
        zIndex="1000"

      >
         <Grid item xs={12} sm={9} md={5} lg={4} xl={3} marginTop={0}>
         <SkeletonTypography loading={loading}/>
         </Grid>
        
      </Grid>
      }
      {success &&
      
      <div ref={containerRef} dangerouslySetInnerHTML={{ __html:  codePayment  }} />
      }
      </PageLayout>
  );
}

export default Basic;