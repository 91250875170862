/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import moment from 'moment'
import { useEffect, useState } from "react";
//import styling
import './styles.css'
// @mui material components
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Typography } from '@mui/material'
import { CardMedia } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import dayjs from 'dayjs';
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import "react-phone-input-2/lib/style.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import Tooltip from '@mui/material/Tooltip'
//import useDispatch
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import firebase auth
import 'firebase/compat/auth';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import components
import { Navbar } from "layouts/components";
import WhatsappSupport from "layouts/components/whatsappSupport";
import Faqs from "layouts/components/faqs";
import SkeletonTypography from "layouts/components/skeleton";
//import images
import noResultImg from "../../assets/images/no-results.png"
import { useSelector } from 'react-redux';
import getSettings from "layouts/first-screen/functions/getSettings";
import ErrorImg from "assets/images/error.png"
function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [welcomeMsg, setWelcomeMsg] = useState("Oups, paiement échoué. Nous te prions de ré-essayer ou de contacter ta banque.");
  const [texte1, setTexte1] = useState("Pour reprendre ton achat, il te suffit de cliquer sur le bouton ci-dessous et re-saisir tes informations.")
  const [loading, setLoading] = useState(false)
  const [faqsData, setFaqsData] = useState([])
  const [settings, setSettings] = useState({})

  useEffect(() => {
    getSettings(setSettings, setLoading, setFaqsData, dispatch)
  }, [])


  return (
    <PageLayout>

            <MDBox
            px={1}
            width="100%"
            bgColor="#fafafa"
          
            height="100vh"
            style={{
              padding: 30,
              overflowY: 'auto', 
              position: 'relative',  
            }}
      
            alignItems="center"
            mx='auto'
          >
    <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <MDBox textAlign="center" marginTop={5}>
          <MDBox  textAlign="center">
              <div className="iconBack">
                <img className="mailIcon" src={ErrorImg} alt="image description" />
              </div>
            </MDBox>
          </MDBox>

          <MDBox textAlign="center" marginTop={-8}>
          <Typography color={"dark"} variant='h4' justifyContent='center' fontWeight='bold'>
           {welcomeMsg}
            </Typography>
          </MDBox>
          <MDBox textAlign="center" marginTop={2}>
          <p className={`text`}>{texte1}</p>
          </MDBox>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={3}
              >
                <a href="/info">
              <MDButton
                     onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='error'
                   >
                    Obtenir mon abonnement
                     
                   </MDButton>
                   </a>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
             </MDBox>

     
      </PageLayout>
  );
}

export default Basic;